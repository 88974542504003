import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Dropdown, Button, Modal } from 'antd';
import cookie from 'react-cookies'
import { recursionRouter, getAllChildrenDataList } from '@/constants/renderNewRouter'
import { SmileTwoTone } from '@ant-design/icons';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

type State = {
  menus: any;
  defaultOpenKeys: string[];
  selectedKeys: string[];
  pageHeaders: string[];
  name: string;
};

@(withRouter as any)
export default class MenuLayout extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    const userName = sessionStorage.getItem('NAME') || ''
    this.state = {
      defaultOpenKeys: [],
      selectedKeys: [],
      pageHeaders: [],
      menus: [],
      name: userName
    };
  }

  componentDidMount(): void {
    this.getMeuns()
  }

  // 菜单获取
  getMeuns = () => {
    const params = {
      parentRuleId: 2,
      type: 1
    }
    return recursionRouter(params).then((data: any) => {
      if (data && data.children) {
        const res = data.children
        this.setState((prevState) => {
          let { defaultOpenKeys } = prevState
          res.forEach((_item: any, index: number) => {
            defaultOpenKeys.push(`${index}`);
          });
          return { ...prevState, menus: res || [],  defaultOpenKeys }
        }, () => {
          this.setSelectedKeys(this.props);
        })
      } else {
        Modal.error({
          title: '提示',
          content: '抱歉，您暂无权限操作',
        })
      }
    })
  }

  // 页面跳转
  onClickMenuItem(data: any) {
    this.getPageRole(data)
  }

  // 获取跳转页面的操作权限
  getPageRole = (data: any) => {
    const params = {
      parentRuleId: data.id,
      type: 2
    }
    getAllChildrenDataList(params).then((res: any) => {
      sessionStorage.setItem('PAGE_ROLE', JSON.stringify(res))
      this.props.history.push(data.data)
    })
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<any>
  ): void {
    this.setSelectedKeys(nextProps)
    if (this.props.updateMenus !== nextProps.updateMenus) {
      this.getMeuns()
    }
  }

  setSelectedKeys(props: any) {
    const { menus } = this.state
    let pageHeaders: any[] = []
    let pathName = props.location.pathname
    let selectedKey = ''
    menus.forEach((item: any, index: number) => {
      if ((item.data && item.data !== '/') && pathName.match(item.data)) {
        selectedKey = `${ index }`
        pageHeaders = [{path: item.data, name: item.name}]
      } else if (item.children) {
        item.children.forEach((child: any, childIndex: number) => {
          if ((child.data) && pathName.match(child.data)) {
            selectedKey = MenuLayout.getSubKeyByIndexAndChildIndex(index, childIndex)
            pageHeaders = [
              {path: item.data, name: item.name},
              {path: child.data, name: child.name}
            ]
          } else if (child.children) {
            
            child.children.forEach((grandchild: any, grandchildIndex: number) => {
              if ((grandchild.data) && pathName.match(grandchild.data)) {
                selectedKey = MenuLayout.getSubKeyByIndexAndChildIndex(index, childIndex, grandchildIndex)
                pageHeaders = [item.name, child.name, grandchild.name]
                pageHeaders = [
                  {path: item.data, name: item.name},
                  {path: child.data, name: child.name},
                  {path: grandchild.data, name: grandchild.name}
                ]
              }
            })
          }
        })
      }
    })
    this.setState({
      selectedKeys: [selectedKey],
      pageHeaders
    })
  }

  itemRender(curRoute: any, headers: any) {
    const first = headers[0].name === curRoute.name;
    const last = headers[headers.length - 1].name === curRoute.name;
    if (headers.length === 0) {
      return null;
    }
    return first || last ? (
      <span
        style={{
          color: last ? '#1890ff' : ''
        }}
      >
        {curRoute.name}
      </span>
    ) : (
      <Link to={curRoute.path}>{curRoute.name}</Link>
    );
  }
  outLog = () => {
    cookie.remove('TOKEN')
    sessionStorage.removeItem('NAME')
    sessionStorage.removeItem('PAGE_ROLE')
    window.location.reload()

  }
  render() {
    const { menus, defaultOpenKeys, selectedKeys, pageHeaders, name } = this.state;
    const menuOut = (
      <Menu>
        <Menu.Item>
          <Button type='link' onClick={ this.outLog }>退出登录</Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <Layout>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0
          }}
        >
          <div className="logo" style={{ color: '#fff' }} />
          <Menu
            defaultOpenKeys={defaultOpenKeys}
            selectedKeys={selectedKeys}
            theme="dark"
            mode="inline"
          >
            {menus.map((menu: any, index: number) =>
              (menu.children && menu.children.length)
                ? this.renderSubMenu(menu, index)
                : this.renderMenuItem(menu, index)
            )}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: 200, minHeight: '100vh' }}>
          <Header
            style={{
              background: '#fff',
              padding: 0,
              position: 'fixed',
              top: 0,
              left: 200,
              right: 0,
              zIndex: 999,
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Breadcrumb
              style={{ height: 64, lineHeight: '64px', paddingLeft: 16 }}
            >
              {pageHeaders.map((item, index) => (
                <Breadcrumb.Item key={item}>
                  {this.itemRender(item, pageHeaders)}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <Dropdown overlay={menuOut}>
              <p
                style={{ 
                  width: 100,
                  textAlign: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                <SmileTwoTone translate={true} twoToneColor={'#52c41a'} style={{ fontSize: 18 }} />
                <span style={{ margin: '0 10px', fontSize: 12 }}>
                  {name}
                </span>
              </p>
            </Dropdown>
          </Header>
          <Content
            style={{
              margin: '88px 16px 0',
              overflow: 'initial',
              minHeight: 'calc(100% - 160px)'
            }}
          >
            <div
              style={{
                padding: 24,
                background: '#fff',
                zIndex: 100,
                minHeight: '100%'
              }}
            >
              {this.props.children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>非烟-后台管理</Footer>
        </Layout>
      </Layout>
    );
  }

  renderSubMenu(menu: any, index: number) {
    return (
      <SubMenu
        key={`${index}`}
        title={
          <span>
            <span>{menu.name}</span>
          </span>
        }
      >
        {menu.children &&
          menu.children.map((child: any, childIndex: number) => (
            <Menu.Item
              onClick={this.onClickMenuItem.bind(this, child)}
              key={MenuLayout.getSubKeyByIndexAndChildIndex(index, childIndex)}
            >
              {child.name}
            </Menu.Item>
          ))}
      </SubMenu>
    );
  }

  private renderMenuItem(menu: any, index: number) {
    return (
      <Menu.Item
       key={`${index}`}
       onClick={this.onClickMenuItem.bind(this, menu)}
      >
        <span className="nav-text">{menu.name}</span>
      </Menu.Item>
    );
  }

  static getSubKeyByIndexAndChildIndex (index: number, childIndex: number, grandchildIndex?: number) {
    if (!!grandchildIndex) {
      return `${ index }-${ childIndex }-${ grandchildIndex }`
    }
    return `${ index }-${ childIndex }`
  }
}
