import http from '@/api/base'

async function getMyMeus (data) {
  return http.get(`/tabacco/v1/rule/treeByRole`, data)
}

export async function recursionRouter (data) {
  let userRouter = await getMyMeus(data).then(res => res || [])
  if (userRouter) {
    return userRouter
  }
}

export async function getAllChildrenDataList (data) {
  return http.get(`/tabacco/v1/rule/getAllChildrenDataList`, data)
}
