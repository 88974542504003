import React from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import MenuLayout from "@/components/menu-layout/MenuLayout";
import routes from "./routes";
import loadable from "@loadable/component";

const Login = loadable(() => import('./login'))

function RouteWithSubRoutes(route: any) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} getNewMenus={route.getNewMenus}/>
      )}
    />
  );
}

export default class App extends React.Component<any, any> {
  constructor (props: any) {
    super(props)
    this.state = {
      updateMenus: false
    }
  }

  getNewMenus = () => {
    this.setState({ updateMenus: true })
  }

  render () {
    const { updateMenus } = this.state
    return (
      <Router>
        <Switch>
          <Redirect from='/' to='/welcome' exact />
          <Route path={'/login'} component={Login} exact />
          <MenuLayout updateMenus={updateMenus} getNewMenus={ this.getNewMenus }>
              {routes.map((route, i) => (
                <RouteWithSubRoutes
                  key={i}
                  {...route }
                  getNewMenus={ this.getNewMenus }
                  exact
                />
              ))}
          </MenuLayout>
        </Switch>
      </Router>
    )
  }
};
