import axios from 'axios'
import qs from 'qs'
import { message } from 'antd'
import { getUserToken } from '@/session/login'
import { clearUndefinedObj } from '@/utils/utils'

let notLoginCallback = () => {
  const url = window.location.origin + '/#/login'
  window.location.href = url
}

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Authorization'] = getUserToken()
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return then(response)
  },
  error => {
    // 对响应错误做点什么
    sessionStorage.setItem('loading', 'false')
    if (error && error.response && error.response.status === 403) {
      message.error(`无权访问`)
      notLoginCallback && notLoginCallback()
    } else if (error && error.response && error.response.status === 401) {
      notLoginCallback && notLoginCallback()
    } else {
      message.error(`${error}`)
    }
    
    return reject(error)
  }
)

// 添加请求拦截器
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，例如加入token
    return config
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

function then(response) {
  try {
    let res = response.data
    if (res.byteLength) {
      return new Buffer(res, 'binary').toString('base64')
    }
    if (res instanceof Blob){
      return Promise.resolve(res)
    }
    if (response.status === 204) {
      return Promise.resolve()
    }
    if (typeof res === 'string') {
      res = JSON.parse(res)
    }
    if (res.code === 1 || res.status === '1') {
      return Promise.resolve(res.data ? res.data : res)
    } else if (res.code === -10) {
      if (res.msg) {
        message.error(res.msg)
      }
      return reject(res)
    } else if (res.code === -1) {
      if (res.msg) {
        message.error(res.msg)
      }
      return reject(res)
    } else if (res.code === -9) {
      if (res.msg) {
        message.error(res.msg).then(() => {})
      }
      return reject(res)
    } else if (res.status === 401) {
      notLoginCallback && notLoginCallback()
      window.location = '/login'
    } else if (res.status === 403) {
      notLoginCallback && notLoginCallback()
      window.location = '/login'
    } else {
      if (res.msg) {
        message.error(res.msg).then(() => {})
      }
      return reject(res)
    }
  } catch (e) {
    // JSON 格式解析失败
    return reject(e)
  }
}

function reject(e) {
  return Promise.reject(getErrorMessage(e))
}

function getErrorMessage(e) {
  if (e.byteLength) {
    return e
  }
  if (e && e.msg) {
    return e
  }
  return {
    ret: -1,
    msg: '网络连接失败，请稍后再试'
  }
}

export const Xurl =
  process.env.REACT_APP_ENV === 'test' ? 'https://zbdx.jzjtong.com/tabacco/' : 'https://tabacco.zbszkj.com/'
export function changeUrl(url) {
  if (process.env.NODE_ENV === 'development') {
    if (url.indexOf('/tabacco') !== -1) {
      return url.replace('/tabacco', `https://zbdx.jzjtong.com/tabacco`)
    } else {
      url = url.replace('/', 'https://zbdx.jzjtong.com')
    }
  } else if (process.env.REACT_APP_ENV === 'production') {
    if (url.indexOf('/tabacco') !== -1) {
      return url.replace('/tabacco', `https://tabacco.zbszkj.com`)
    } else {
      return url.replace('/', `https://zbdx.zbszkj.com`)
    }
  } else if (process.env.REACT_APP_ENV === 'test') {
    if (url.indexOf('/tabacco') !== -1) {
      return url.replace('/tabacco', `https://zbdx.jzjtong.com/tabacco`)
    } else {
      url = url.replace('/', 'https://zbdx.jzjtong.com')
    }
  }
  return url
}
// /power/admin
export default class http {
  static get(url, data, arr) {
    url = changeUrl(url)
    if (arr) {
      return axios.get(`${url}`, {
        params: clearUndefinedObj(data),
        paramsSerializer: function(params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
    }
    return axios.get(`${url}`, arr ? qs.stringify(data, { arrayFormat: 'repeat' }) : { params: data })
  }

  static getBase64(url) {
    url = changeUrl(url)
    return axios.get(url, {
      responseType: 'arraybuffer',
      withCredentials: true
    })
  }

  static getImg(url, data) {
    url = changeUrl(url)

    return axios.get(`${url}`, { params: data }, { responseType: 'blob' })
  }

  static post(url, data, formData) {
    url = changeUrl(url)
    const clearData = clearUndefinedObj(data)

    return axios.post(`${url}`, formData ? clearData : qs.stringify(clearData, { arrayFormat: 'repeat' }), {
      withCredentials: true
    })
  }

  static postBlob(url, data, formData) {
    url = changeUrl(url)
    return axios.post(`${url}`, formData ? data : qs.stringify(data, { arrayFormat: 'repeat' }), {
      withCredentials: true,
      responseType: 'blob'
    })
  }

  static postBody(url, data, isFormData) {
    url = changeUrl(url)

    return axios.post(`${url}`, data)
  }

  static put(url, data, isRawData) {
    url = changeUrl(url)

    return axios.put(`${url}`, isRawData ? data : qs.stringify(data, { arrayFormat: 'repeat' }))
  }

  static apiDelete(url, data) {
    url = changeUrl(url)

    return axios.delete(`${url}`, {
      data: qs.stringify(data, { arrayFormat: 'repeat' })
    })
  }

  static deleteBody(url, datas) {
    url = changeUrl(url)

    return axios.delete(`${url}`, { data: datas.ids })
  }

  static setNotLoginCallback(callback) {
    notLoginCallback = callback
  }

  static updateAuthorization() {
    axios.defaults.headers.common['Authorization'] = getUserToken()
    return getUserToken()
  }
}
