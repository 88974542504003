import cookie from 'react-cookies'
import { SYSTEM_ID } from '@/constants/parmas'
/**
 * 清除obj中undefined项
 * @param {object} obj
 */
function clearUndefinedObj(obj) {
  const objCopy = { ...obj }
  Object.keys(objCopy).map(key => {
    if (objCopy[key] !== 0 && !objCopy[key]) {
      delete objCopy[key]
    }
  })
  return objCopy
}

/**
 * 清除对象数组中重复的数据, 根据给出的key清除
 * @param {object} objAarr
 * @param {string} key
 */
function objRepeat(objAarr, key) {
  var hash = {}
  // reduce方法有两个参数，第一个参数是一个callback，用于针对数组项的操作；第二个参数则是传入的初始值，这个初始值用于单个数组项的操作。
  const objArray = objAarr.reduce(function(item, next) {
    // 这是针对数组项操作的函数，对于每个数组项，reduce方法都会将其调用一次
    // 可使用最多四个参数来声明回调函数（第一个参数：通过上一次调用回调函数获得的值;第二个参数：当前数组元素的值；第三个参数：当前数组元素的数字索引；第四个参数：包含该元素的数组对象）
    // 这里只使用了两个参数，所以item和next分别代表上一次调用回调函数后的值和objArray项值
    if (!hash[next[key]]) {
      hash[next[key]] = true && item.push(next)
    }
    return item
  }, []) // 初始值是一个空对象，使用reduce方法返回的是空对象通过叠加执行之后的结果
  return objArray
}

function windowOpen(url) {
  console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'development') {
    window.open('#' + url)
  } else {
    window.open(window.location.origin + window.location.pathname + '#' + url)
  }
}
function windowLocation (url) {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = ('#' + url)
  } else {
    window.location.href = (window.location.origin + window.location.pathname + '#' + url)
  }
}
function getQueryString(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const search = window.location.href.substr(window.location.href.indexOf('?'))
  let r = search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}
function getQueryStringChinese (name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const search = window.location.href.substr(window.location.href.indexOf('?'))
  let r = search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}
// 防重复点击
/**
 * 装饰器版防抖
 * @param delay 延迟时间
 * @param immediately 是否立即执行(会先执行一次，再进入定时器模式)
 */
const debounceAt = function(delay = 300, immediately) {
  return function(target, name, descriptor) {
    let timer = null
    let isFirst = immediately

    // high order function
    if (!descriptor || (arguments.length === 1 && typeof target === 'function')) {
      return createDebounce(target)
    }

    function createDebounce(fn) {
      return function debounce() {
        clearTimeout(timer)
        if (immediately) {
          fn.apply(this, arguments)
          immediately = false
          isFirst = true
        }
        if (isFirst) {
          timer = setTimeout(() => {
            isFirst = false
            immediately = true
          }, delay)
        } else {
          timer = setTimeout(() => {
            immediately = false
            fn.apply(this, arguments)
          }, delay)
        }
      }
    }

    // 修饰类内的箭头函数
    if (descriptor.initializer) {
      return {
        enumerable: false,
        configurable: true,
        get: function() {
          return createDebounce(descriptor.initializer.call(this))
        }
      }
    }

    return descriptor
  }
}

  //  通过cookie获取当前系统得系统id
  function getSystemId () {
    let system = cookie.load('system') // cookie里得所有系统信息
    let systemId = 0 // 最终返回的系统Id
    if (system && Array.isArray(system)) {
      let urlHref = ''
      if (process.env.NODE_ENV === 'development') {
        // urlHref = window.location.origin
        //  因为本地环境得 url 为  localhost ,因此作为和测试环境得区分,需要在开发环境直接返回权限中心得系统 id
        return SYSTEM_ID
      } else {
        urlHref = window.location.origin + window.location.pathname
      }
     let systemVar = system.find((item) => item.path && item.path.indexOf(urlHref) !== -1) // 返回相对应的对象
     if (systemVar) {
      systemId = systemVar.id
      return systemId
     }
    }
    return false
  }

// 权限判断
function checkRole () {
  const params = {}
  const role = sessionStorage.getItem('PAGE_ROLE')
  const pageRole = role ? JSON.parse(role) : []
  pageRole.map((item) => {
    if (item === 'list') {
      params.list = true
    }
    if (item === 'add') {
      params.add = true
    }
    if (item === 'edit') {
      params.edit = true
    }
    if (item === 'delete') {
      params.delete = true
    }
    if (item === 'operation') {
      params.operation = true
    }
    if (item === 'import') {
      params.import = true
    }
    if (item === 'export') {
      params.export = true
    }
  })
  return params
}

export { clearUndefinedObj, objRepeat, windowOpen, windowLocation, getQueryString, debounceAt, getSystemId, getQueryStringChinese, checkRole }
