import loadable from '@loadable/component'

// 欢迎页
const Welcome = loadable(() => import('./welcome'))

// 角色管理
const RoleList = loadable(() => import('./user-center/role/list/index'))
const RoleEdit = loadable(() => import('./user-center/role/edit/index'))
const UserList = loadable(() => import('./user-center/user-set/list/index'))
const UserEdit = loadable(() => import('./user-center/user-set/edit/index'))
// 地区管理
const AreaList = loadable(() => import('./area-manage/area-list/index'))
const AreaEdit = loadable(() => import('./area-manage/area-edit/index'))
// 非烟商品管理
const TabaccoGoodsList = loadable(() => import('./tabacco-goods/goods-list/index'))
const TabaccoGoodsEdit = loadable(() => import('./tabacco-goods/goods-edit/index'))
// 非烟报表
const AreaOfferFormList = loadable(() => import('./tabacco-report-form/area-offer-form/index'))
const UserOfferFormList = loadable(() => import('./tabacco-report-form/user-offer-form/index'))

const routes = [
  {
    path: '/welcome',
    component: Welcome
  },
  {
    path: '/user-manage/role',
    component: RoleList
  },
  {
    path: '/user-manage/role-edit/:id',
    component: RoleEdit
  },
  {
    path: '/user-manage/role-add',
    component: RoleEdit
  },
  {
    path: '/user-manage/user',
    component: UserList
  },
  {
    path: '/user-manage/user-edit/:id',
    component: UserEdit
  },
  {
    path: '/user-manage/user-add',
    component: UserEdit
  },
  {
    path: '/area-manage/area',
    component: AreaList
  },
  {
    path: '/area-manage/area-add',
    component: AreaEdit
  },
  {
    path: '/tabacco-goods/goods',
    component: TabaccoGoodsList
  },
  {
    path: '/tabacco-goods/goods-add',
    component: TabaccoGoodsEdit
  },
  {
    path: '/tabacco-goods/goods-edit/:id',
    component: TabaccoGoodsEdit
  },
  {
    path: '/tabacco-report-form/area-offer',
    component: AreaOfferFormList
  },
  {
    path: '/tabacco-report-form/user-offer',
    component: UserOfferFormList
  }
]

export default routes
