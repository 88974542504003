import cookie from 'react-cookies'
import { getQueryString, getQueryStringChinese } from '@/utils/utils'

export function getUserToken () {
  const url_token = getQueryString('token')
  const userName = getQueryStringChinese('name')
  let token = cookie.load('TOKEN')
  if (url_token) {
    cookie.save('TOKEN', url_token)
    token = url_token
  }
  if (userName) {
    sessionStorage.setItem('NAME', userName)
  }
  return token
}
